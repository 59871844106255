<template>
  <v-dialog
    v-model="progress"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      light
    >
      <v-card-text>
        Please stand by, {{ title }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters } = createNamespacedHelpers('app')

  export default {
    name: 'ProgressDialog',
    data: () => ({
      title: 'loading Blitz data...',
      dialog: false, // was true mean dialog will appear
    }),
    computed: {
      ...mapGetters(['progress',
      ]),
    },

  }
</script>

<style scoped>

</style>
